<!-- Informes Funerarias Sucursales -->

<template>
  <div class="central_Report_F" v-if="schema && $store.state[stName].recordsDetalle.length">
    <div class="conflex" style="justify-content:center">
      <v-sheet elevation="4">

        <!-- Cabecera -->
        <div v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>
        
        <!-- Botonera -->
        <div class="pt-2 pl-2">
          <btraextra
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="top"
            @onEvent="event_btra_Top">              
          </btraextra>
        </div>

        <!-- Contenido -->
        <div class="contenedor conflex" style="width:950px">
          <div class="columna" style="width:32%">
            <!-- Menú -->  
            <div class="cab">Tipos de Informes</div>
            <v-sheet v-bind="$cfg.styles.marco">        
              <v-treeview
                :items="$store.state[stName].recordsDetalle[0]"
                item-key="d"
                item-text="n"
                item-children="c"
                activatable
                dense
                open-on-click
                return-object
                @update:active="change_inf">            
              </v-treeview>
            </v-sheet>
          </div>

          <div class="columna" style="width:66%;margin-left:10px">
            <div class="cab">Condiciones</div>
            <v-sheet v-bind="$cfg.styles.marco"> 
              <div style="conflex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.ord.value"
                  :label="schema.ctrls.ord.label"
                  :items="ord"
                  item-value="d"
                  item-text="n">              
                </v-select>
              </div>
          
              <div style="display:flex">                                                 
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.txt.value"
                    :label="schema.ctrls.txt.label">              
                  </v-text-field>

                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.sit.value"
                    :label="schema.ctrls.sit.label">                
                  </v-text-field>
              </div>
              <div style="display:flex">
                   <v-select   
                    style="flex: 0 0 60%"               
                    v-bind="$select"
                    v-model="schema.ctrls.tip.value"
                    :label="schema.ctrls.tip.label"
                    :items="itemsTipos"
                    item-value="id"
                    item-text="name">
                  </v-select>                                                              

                <v-select
                  style="flex: 0 0 40%"
                  v-bind="$select"
                  v-model="schema.ctrls.grupo.value"
                  :label="schema.ctrls.grupo.label"            
                  :items="$store.state[stName].recordsDetalle[2]" 
                  item-value="d"
                  item-text="n"          
                  multiple>                              
                    <template v-slot:selection="{ index }">              
                        <span  
                        v-if="index=== 0"              
                        class="grey--text caption">                
                            ({{ schema.ctrls.grupo.value.length }} grupo/s seleccionado/s)
                        </span>
                    </template>
                </v-select>
              </div>
              
            <div style="display:flex">                              
                <ctrlfinder style="flex: 0 0 100%" finderName="atlas_F" :schema="schema.ctrls.ori"></ctrlfinder>
            </div>                                                    

            </v-sheet>
          </div> 
          
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import { mixinReport } from "@/mixins/mixinReport.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btraextra = () => plugs.groute("btraextra.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  
  export default {
    mixins: [mixinFinder, mixinReport],
    components: { base_Header, btraextra, ctrlfinder, },
    props: {
      componenteTipo: { type:String, default:'F' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'central_Report_F',
        stName:'stFcentral_Report',

        headers: {},
        ord: [],           
        
        itemsTipos:[],        
          
      };
    },

    computed: {

    },

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
      
        // configuro Entorno          
        this.Entorno.header.titulo = "Informe Centrales";
        this.Entorno.grid.autoload= true;
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));        
        this.Entorno.btra.footer.extra= {};
        this.Entorno.btra.footer.extra= [
          { accion: "ver_Pdf", btn: "btn_pdf", show: 1 },
          { accion: "ver_Excel", btn: "btn_excel", show: 1 }          
        ];

        // items adicionales        
        this.itemsTipos= this.$store.state.datos_iniciales.tipo_empresa.slice();        
        this.itemsTipos.unshift({id:'0',name:'( Selecciona Tipo )'});
        // this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();        
        // this.itemsZonas.unshift({id:'0',name:'( Selecciona Zona )'});

      },

      //
      ini_schemaCtrls() {        
        //this.schema.ctrls.zona.value= this.zona;
      }, 

      // muestro informe en pdf
      ver_Pdf() {       
        this.show_report("pdf");       
      },

      // muestro informe en excel
      ver_Excel() {        
        this.show_report("xls");
      },


      // muestro informe
      async show_report(tipo) {
        // compruebo si hay seleccionado un Informe
        if (this.schema.ctrls.inf.value== "") {
          this.$root.$alert.open('Debes seleccionar un informe', 'error');
          return;
        }

        // monto los argumentos de la API
        let fn_args= {};
        fn_args['accion']= "informe";
        fn_args["ctrls"]= this.ctrl2linea(this.schema.ctrls);
        fn_args['report']= "central";
        fn_args['tip']= tipo;

        this.report(fn_args);
      }

    }
  };
</script>
