export var mixinReport = {
  // acciones iniciales del componente
  /* async created() {
    // modifico configuración particular del entorno
    await this.get_entorno_Report();

    // obtengo schema particular
    await this.get_schema_Report();
  }, */

  async created() {
    // el COMPONENTETE no su pinta hasta que salga del created() 
    /* this.ini_tipoComponente();

    // inicializo datos auxiliares del finder
    this.ini_data();

    // obtengo schema particular
    console.log("DEV " + this.Entorno.api + " **********MIXINREPORT (created)");

    // Recuperar schema finder
    this.schema = await this.$store.dispatch("get_schema", { tipo: "R", name: this.Entorno.api });    
    console.log("DEV " + this.Entorno.api + " **********MIXINREPORT get_schema(paso await)", this.schema);

    //Inicializar valores controles schema.ctrls si se necesita para el filtro
    this.ini_schemaCtrls(); */

  },


  methods: {
    // OVERRIDE DE FUNCIONES--------------------------------------------        
    // inicializa entorno y datos auxiliar
    //ini_data(){},
    // inicializa ctrls de filtro
    //ini_schemaCtrls(){},    
    //-----------------------------------------------------------------


    //
    /* ini_tipoComponente(){
      this.Entorno.btra= this.componenteTipo;     
    }, */

    // entorno Report
    /* get_entorno_Report() {}, */

    // obtengo schema particular del Api/Store
    /* async get_schema_Report() {
      if (this.schema) return;
      this.schema = await this.$store.dispatch("get_schema", {
        tipo: "R",
        name: this.Entorno.report
      });
    }, */

    // ---------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------- FN DE EVENTOS ---------------------------------------------
    // ---------------------------------------------------------------------------------------------------------------
  
    // eventos de la cabercera
    /* event_Header(evt) {
      console.log("DEV " + this.Entorno.api + " event_Header ********** MIXFINDER", evt);
      // Pendiente. Acciones al cerrar un Finder
      if (evt.accion==='cerrar') this.$emit('onEvent', evt);
    }, */

    //
    async event_btra_Report(evt) {
      console.log("*** event_btra_Report. Accion: ", evt, " ***");

      /* switch (evt.accion) {
        case 0: // imprimir
          break;

        case 1: // pdf
        case 2: // excel
          // compruebo que haya elegido un informe
          if (
            !this.$store.state.schemas.report[this.Entorno.report].ctrls.inf
              .value
          ) {
            this.$root.$alert.open(
              "Debes seleccionar un Informe",
              "info",
              2000
            );
            return;
          }

          // obtengo nombre informe
          var data = this.filtrar_Report(
            evt.accion === 1 ? "pdf" : "excel",
            this.schema.ctrls
          );
          var args = {
            tipo: "R",
            api: this.Entorno.report,
            accion: "informe",
            fn_args: data
          };
          console.log("Send: ", args);
          var r = await this.$store.dispatch("ajaxRequest", { args: args });

          // muestro informe
          console.log(this.$store.state.G.url_tmp + r);
          window.open(this.$store.state.G.url_tmp + r, "_blank");

          break;

        case 3: // salir
          break;
      } */
    },

    //
    filtrar_Report(accion, ctrls) {
      let datos_filtro = {};

      // recorro el array de controles del Filtro recibido y voy guardando los valores
      // de los campos que tengan la propiedad 'f' definida
      for (const key in ctrls) {
        if (ctrls[key].f !== undefined)
          datos_filtro[ctrls[key].f] = ctrls[key].value;
      }

      return { datos_filtro: datos_filtro, name_btnPulsado: accion };
    },

    // entro cada vez que seleccionamos un informe
    change_inf(data) {
      console.log('change_inf: ', data);
      var elem = data[0];

      // actualizo value del control 'inf' al índice del informe seleccionado
      this.schema.ctrls.inf.value = elem.d;

      // si el informe seleccionado tiene un array de orden, modifico el select de orden
      if (typeof elem.o !== "undefined") {
        this.ord = this.$store.state[this.stName].recordsDetalle[1][elem.o];
        this.schema.ctrls.ord.value = "0";
      }

      this.change_inf_fin();
    },

    //
    change_inf_fin() {},

    //
    async report(fa) {
      let srv=1;

      // llamada a la API          
      var args = { tipo: 'fnc', accion: 'report', fn_args: fa };                    
      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args, srv: srv});
              
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      let url=(srv==1 ? this.$store.state.G.inf_tmp : this.$store.state.G.url_tmp);
      window.open(url + apiResult.r[0], "_blank");     

    },

  }
};
